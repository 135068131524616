/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/lazysizes@5.3.2/plugins/bgset/ls.bgset.min.js
 * - /npm/@dotsunited/load-css-polyfill@1.2.0/dist/load-css-polyfill.auto.min.js
 * - /npm/jquery.easing@1.4.1/jquery.easing.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
